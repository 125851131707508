import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import {Input, Select, Grid, Empty, Divider, Skeleton} from "antd";
import { Flex } from "antd";
import { css } from "styled-components";
import { Pagination } from 'antd';
import ExpectationsList from "./ExpectationsList";
import { getExpectationsList } from "../../utils/ExpectationsParser";
import DisplayLink from "./shared/DisplayLink";
import { posthogCapture } from "../shared/posthogCapture";

const { useBreakpoint } = Grid;

const Section = styled.section`
    background-color: ${props => props.theme.colors.lightGray};
    color: ${props => props.theme.colors.gray};
    width: 100%;
`;

const FiltersContainer = styled.div`
    background-color: ${props => props.theme.colors.mediumGray};
    border-bottom: 1px solid ${props => props.theme.colors.mediumGrayDark};
`;

const ResponsiveContainer = css`
    margin: 0 auto;
    max-width: 1200px;
`;

const Filters = styled(Flex)`
    ${ResponsiveContainer};
    padding: 0.875rem 2rem 1.25rem;
`;

const SearchInput = styled(Input)<{$lg?: boolean}> `
    border-radius: ${props => props.theme.borderRadius.sm};
    height: 2rem;
    max-width: ${({$lg}) => $lg ? "280px" : "unset"};
    width: 100%;

    &::placeholder {
        color: ${props => props.theme.colors.darkGray};
    }
`;

const SingleSelect = styled(Select) `
    min-width: 10rem;
    & .ant-select-selection-placeholder,
    & .ant-select-selection-search,
    & .ant-select-arrow {
        color: ${props => props.theme.colors.darkGray};
    }
`;

const ExpectationsContainer = styled.div`
    ${ResponsiveContainer};
    padding: 4rem 2rem 5rem;
`;

const PaginationWrapper = styled(Flex) `
    padding: 2rem;

    & .ant-pagination-item {
        border-radius: 50%;
        
        &.ant-pagination-item-active a {
            color: ${props => props.theme.colors.white};
        }
        
        &:not(.ant-pagination-item-active) {
            background-color: rgba(64, 64, 65, .1);
            color: ${props => props.theme.colors.gray};
        }
    }
    
    & .ant-pagination-disabled {
        opacity: 0;
    }

    & .ant-pagination-prev,
    & .ant-pagination-next {
        background-color: #E1E1E4;
        border-radius: 50%;
        
        &:hover .ant-pagination-item-link {
            border-radius: 50%;
        }
    }
`;

const EmptyDescription = styled.span`
    font-weight: ${props => props.theme.fontWeight.semibold};
    color: ${props => props.theme.colors.darkGray};
`;

const LegacyGallery = styled.p`
    color: ${props => props.theme.colors.darkGray};
    font-family: Source Sans Pro, sans‑serif,serif;
    font-size: ${props => props.theme.fontSize.lg};
    max-width: 30rem;
    margin: 0 auto;
    text-align: center;
    a {
        text-decoration: underline;
    }
`;

const allExpectations = getExpectationsList();

const allDataSources = [
    { value: "pandas", label: "Pandas" },
    { value: "spark", label: "Spark" },
    { value: "sqlite", label: "SQLite" },
    { value: "postgresql", label: "PostgreSQL" },
    { value: "mysql", label: "MySQL" },
    { value: "mssql", label: "MSSQL" },
    { value: "redshift", label: "Redshift" },
    { value: "bigquery", label: "BigQuery" },
    { value: "snowflake", label: "Snowflake" }
];

const allDataQualityIssues = [
    { value: "data integrity", label: "Data integrity" },
    { value: "missingness", label: "Missingness" },
    { value: "pattern matching", label: "Pattern matching" },
    { value: "cardinality", label: "Cardinality" },
    { value: "sets", label: "Sets" },
    { value: "distribution", label: "Distribution" },
    { value: "numerical data", label: "Numerical data" },
    { value: "schema", label: "Schema" },
    { value: "volume", label: "Volume" }
];

const dataSources = allDataSources.filter( ds => allExpectations.find(exp => exp.supported_data_sources.find(supDataSource => supDataSource.toLowerCase().includes(ds.value))));
const dataQualityIssues = allDataQualityIssues.filter( ds => allExpectations.find(exp => exp.data_quality_issues.find(supDataSource => supDataSource.toLowerCase().includes(ds.value))));

const expectationMatchSearch = (expectation, search) => expectation.title.toLowerCase().includes(search.toLowerCase()) || expectation.short_description.toLowerCase().includes(search.toLowerCase());
const expectationMatchDataSource = (expectation, dataSource) => expectation.supported_data_sources.find( ds => ds.toLowerCase().includes(dataSource ? dataSource.value : ''));
const expectationMatchDataQuality = (expectation, dataQualityIssue) => expectation.data_quality_issues.find( dqi => dqi.toLowerCase().includes(dataQualityIssue ? dataQualityIssue.value : ''));

const ExpectationsSection = () => {
    const PAGE_SIZE = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredExpectations, setFilteredExpectations] = useState(allExpectations);
    const [expectations, setExpectations] = useState(allExpectations.slice(0, PAGE_SIZE));
    const { lg } = useBreakpoint();

    const [filter, setFilter] = useState({
        search: '',
        dataSource: undefined,
        dataQualityIssue: undefined
    });

    const handleSearch = (e) => {
        updateFilter('search', e.target.value);
    };

    const handleDataSource = (selected) => {
        updateFilter('dataSource', selected);
        if (selected) {
            posthogCapture("data_source_selected", { title: selected.label })
        }
    };

    const handleDataQualityIssue = (selected) => {
        updateFilter('dataQualityIssue', selected);
        if (selected) {
            posthogCapture("data_quality_issue_selected", {title: selected.label})
        }
    };

    const updateFilter = (key, value) => {
        const newFilter = { ...filter, [key]: value };
        setFilter(newFilter);
        handleFilterChange(newFilter);
    };

    const handleFilterChange = (newFilter) => {
        const filteredResults = allExpectations.filter(exp => expectationMatchSearch(exp, newFilter.search) && expectationMatchDataSource(exp, newFilter.dataSource) && expectationMatchDataQuality(exp, newFilter.dataQualityIssue));
        setFilteredExpectations(filteredResults);
        setExpectations(filteredResults.slice(0, PAGE_SIZE));
        setCurrentPage(1);
    };

    const handlePaginationChange = (page: number, pageSize: number) => {
        const prevAmountOfItems = pageSize * (page - 1);
        setExpectations(filteredExpectations.slice(prevAmountOfItems, prevAmountOfItems + pageSize));
        setCurrentPage(page);
    };

    return (
        <Section>
            <FiltersContainer>
                <Filters
                    align={lg ? "center" : "stretch"}
                    gap={lg ? "large" : "middle"}
                    vertical={!lg}
                    wrap
                >
                    <SearchInput placeholder="Search..." $lg={lg} value={filter.search} onChange={handleSearch}/>
                    <SingleSelect placeholder="Data source" labelInValue options={dataSources} value={filter.dataSource} onChange={handleDataSource} allowClear/>
                    <SingleSelect placeholder="Data quality issue" labelInValue options={dataQualityIssues} value={filter.dataQualityIssue} onChange={handleDataQualityIssue} allowClear/>
                </Filters>
            </FiltersContainer>
            <ExpectationsContainer>
                { !expectations.length && <Empty image="/images/empty-box.svg"
                        description={<EmptyDescription>No Expectations match your search</EmptyDescription>}/> }
                <ExpectationsList expectations={expectations}/>
                <PaginationWrapper justify="center">
                    <Pagination total={filteredExpectations.length} pageSize={PAGE_SIZE} current={currentPage} hideOnSinglePage onChange={handlePaginationChange}/>
                </PaginationWrapper>
                <Divider style={{maxWidth: '19rem', minWidth: "unset", margin: "2rem auto" }} />
                <LegacyGallery>If you’re looking for a previous Expectation that is no longer supported, please visit the <DisplayLink linkObject={{title: "Legacy Gallery", url: "/legacy/v1/expectations"}}/>.</LegacyGallery>
            </ExpectationsContainer>
        </Section>
    );
};

export default ExpectationsSection;
